.homeContent {

  // padding-top: 34px;
  .sliderBox {
    background: #0000;
    position: relative;
    z-index: 0;
    text-align: center;

    .sliderBoxBackground {
      background-color: rgba(0, 0, 0, .4);
      padding: 100px 0;
    }

    h2 {
      text-align: center;
      font-weight: 500;
      font-size: 50px;
      color: #fff;
      text-transform: uppercase;
      font-size: 40px;
    }

    p {
      text-align: center;
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 24px;
      margin: 0 0 40px 0;

    }

    // button{
    //     display: block;
    //     margin: 0 auto;
    //     font-size: 20px;
    //     padding: 10px 20px;
    //     background: #144795;
    //     color: #fff;
    //     border-radius: 100px;
    //     border: none;
    //     font-weight: 500;
    //     letter-spacing: 3px;
    //     text-transform: uppercase;
    //     &:hover{
    //         background: #fff;
    //         color: #144795;
    //         transition: all ease-in-out 0.4s;
    //     }
    // }
    img {
      width: 100%;
    }
  }
}

.signInlinkInfo {
  font-weight: 500;
  font-size: 14px;

  @media screen and (max-width: 991px) {
    display: none;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;
  }
}

.homeBanner {
  .carousel-caption {
    bottom: 50%;
    transform: translateY(50%);

    h3 {
      font-size: 30px;
      text-transform: uppercase;
    }

    .btn-primary {
      font-size: 16px;
      letter-spacing: 1.4px;
      border-radius: 20px;
      padding: 6px 26px;
    }
  }

  .carousel-inner>div {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
    }
  }

}


.productSection {
  padding: 0px 0;

  .slick-list {
    margin: 0 -15px;
  }

  .slick-slide>div {
    padding: 0 15px;
  }
}

@media (min-width: 390px) and (max-width: 520px) {
  .commonHeading {
    text-align: center;
    margin-bottom: 50px;
  
    h2 {
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 15px;
  
      span {

        font-weight: 700;
        text-transform: uppercase;
      }
    }
}
}
@media (min-width: 577px) and (max-width: 1600px) {
  .commonHeading {
    text-align: center;
    margin-bottom: 50px;
  
    h2 {
      font-size: 26px;
      font-weight: 300;
      margin-bottom: 15px;
  
      span {

        font-weight: 500;
        text-transform: uppercase;
      }
    }
}



  .viewAllBtn {
    font-size: 14px;
    color: #144795;
    letter-spacing: 4.4px;
    text-decoration: none;
  }
}

.viewAllBtnRight {
  font-size: 14px;
  color: #144795;
  letter-spacing: 4.4px;
  text-decoration: none;
  float: right;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-prev::after,
.slick-next:after {
  content: "";
  width: 14px;
  height: 25px;
  background-size: 100%;
  position: absolute;
  top: -2px;
  left: 0;
}

.slick-prev::after {
  background: url(../assets/images/angle-left.png);
}

.slick-next:after {
  background: url(../assets/images/angle-right.png);
}

.productSection {

  .slick-prev,
  .slick-next {
    top: 34% !important;
    z-index: 99999 !important;
  }

}

.slick-prev {
  @media screen and (max-width: 575px) {
    left: -20px;
  }
}

.slick-next {
  @media screen and (max-width: 575px) {
    right: -25px;
  }
}

.homeContent {

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
    width: 54px;

  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: #000;
    padding: 20px;
    opacity: 1;
    background-size: 22px;
    filter: invert(1);
    box-shadow: inset 0 0 0 1px #181818;
  }

}


.eachhotDeals {
  padding-right: 200px;
  position: relative;

  @media screen and (max-width: 575px) {
    padding-right: 0;
  }

  >img {
    width: 100%;
    display: block;
  }

  .eachHotDealsInr {
    position: absolute;
    top: 50%;
    right: 18px;
    background: #fff;
    width: 338px;
    text-align: center;
    padding: 38px 0;
    transform: translateY(-50%);

    @media screen and (max-width: 575px) {
      right: 45px;
      width: calc(100% - 90px);
      padding: 18px 0;
      top: 70%;
    }

    &::after {
      content: "";
      width: calc(100% + 36px);
      height: calc(100% + 36px);
      position: absolute;
      top: -18px;
      left: -18px;
      border: 8px solid #fff;
      box-shadow: 0 0 10px #00000033;
    }

    h2 {
      font-size: 30px;
      color: #000000;
      font-weight: 400;
    }
  }

  .shopNowDeal {
    font-size: 16px;
    color: #144795;
    letter-spacing: 1.2px;
    text-decoration: none;
  }
}

.makeAnIcredibleProduct {
  background: url(../assets/images/background.jpg);
  background-size: cover;
  padding: 120px 0;
  margin-top: 140px;
  margin-bottom: 120px;

  @media screen and (max-width:1400px) and (min-width:992px) {
    padding: 100px 0;
  }

  @media screen and (max-width:991px) {
    padding: 60px 0;
    margin-bottom: 0;
  }
}

.customizeFesection {
  @media screen and (max-width: 767px) {
    order: 1;
    margin-top: 20px;
    text-align: center;
  }

  h2 {
    color: #fff;
    font-weight: 300;
    margin-bottom: 25px;
    font-size: 34px;

    @media screen and (max-width: 767px) {
      font-size: 28px;
    }

    b {
      font-weight: 700;
    }
  }

  .makeAnIncredible.btn-primary {
    background: #048afc;
    font-weight: 500;
    letter-spacing: 1.4px;
    border-radius: 20px;
    font-size: 16px;
    padding: 8px 28px;
  }
}

.makeIncredibleImage {
  margin: -190px 0 -190px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 991px) and (min-width: 768px) {
    margin: -80px 0;
  }

  @media screen and (max-width: 767px) {
    margin: 0;
  }

  img {
    width: 100%;
  }
}



//css added by pritam 

.testimonialSec {
  padding: 0 0 90px 0;

  @media screen and (max-width:991px) {
    padding: 0 0 60px 0;
  }

  .slick-dots {
    position: absolute;
    bottom: 30px;
  }

  .slick-dots li {
    &.slick-active {
      button:before {
        color: #144795;
      }
    }

    button:before {
      font-size: 16px;
      color: #dadada;
      opacity: 1;
    }
  }

  .testimonial-txt {
    box-shadow: 0px 11px 21px 0px rgba(0, 0, 0, 0.06);

    @media screen and (min-width:992px) {
      transform: translateX(-10%);
    }

    .testimonial_owl {
      padding: 30px 0 60px;
      background: #fff;


    }
  }

  .testimonial_owl h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
  }

  .testimonial_owl h4 {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
    margin: 0;
  }

  .testimonial_owl p {
    text-align: center;
    max-width: 447px;
    margin: 0 auto;

    font-size: 18px;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
    padding-bottom: 15px;

    @media screen and (max-width: 1400px) {
      font-size: 14px;
    }
  }

  .testimonial-img {
    @media screen and (min-width:992px) {
      transform: translateX(10%);
    }

    img {
      width: 100%;
    }
  }

  .testimonial-txt .owl-theme .owl-dots .owl-dot.active span,
  .testimonial-txt .owl-theme .owl-dots .owl-dot:hover span {
    background: #144795;
  }

  .testimonial_owl span {
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
  }

  .testimonial_owl img {
    width: 15%;
    margin: 0 auto;
    display: block;
    padding: 20px 0;
  }


}





.feedSection {
  position: relative;
  padding-bottom: 70px;

  .item {
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .feedVideoWrap {
    order: -1;
  }
}


.feedSection h3 {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  padding-bottom: 30px;
}

.feedSection span {
  font-weight: 500;
}

.feedSection .row {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.feedSection .container {
  max-width: 93%;
}

.feedSection .item-img img {
  width: 100%;
}

.feedSection .owl-prev {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  background: #fff;
  color: #000;
  border-radius: 100%;
  font-size: 20px;
}

.feedSection .owl-prev:hover {
  background: #144795;
  color: #fff;
  -webkit-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
}

.feedSection .owl-next {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  width: 30px;
  height: 30px;
  background: #fff;
  color: #000;
  border-radius: 100%;
  font-size: 20px;
}

.feedSection .owl-next:hover {
  background: #144795;
  color: #fff;
  -webkit-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
}

.feedSection .item-img {
  position: relative;
  background: url(../assets/images/feeds-l.jpg) center center/cover;
  height: 100%;
  padding: 200px 0 0 0;

  @media screen and (max-width: 991px) {
    padding: 0;
    background: none;
  }
}

.feedSection .item-img .item-img-txt {
  background: #fff;
  max-width: 556px;
  margin: 0 auto 20px;
  position: relative;
  padding: 30px 0;
  z-index: 1;

  @media screen and (max-width: 991px) {
    margin: 0;
  }
}

.feedSection .item-img .item-img-txt h4 {
  text-align: center;
  font-size: 22px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.feedSection .item-img .item-img-txt h5 {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #888888;
  font-family: 'Roboto', sans-serif;
}

.feedSection .item-img .item-img-txt p {
  text-align: center;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0 auto;
  max-width: 489px;
  padding: 10px 0;
}

.feedSection .item-img .item-img-txt span {
  display: block;
  text-align: center;
}

.feedSection .item-img .item-img-txt span a {
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #000;
  text-decoration: none;
}

.feedSection .item-img::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
}

.feedSection .item-video {
  background: url(../assets/images/feeds-r.jpg) center center/cover;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;

  @media screen and (max-width: 991px) {
    min-height: 200px;
  }

}

.feedSection .item-video span {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.feedSection .item-video span:hover {
  -webkit-transition: all ease-in-out .4s;
  transition: all ease-in-out .4s;
  background: #144795;
}

.feedSection .item-video::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
}






.sellerSec {
  position: relative;
  margin-bottom: 75px;
  z-index: 1;

  &::after {
    content: "";
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: #144795;
    z-index: -1;

    @media screen and (max-width: 991px) {
      width: 100%;
      height: 50%;
      top: 50%;
    }
  }

  .row {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .seller {
    padding: 80px 60px;
    height: 100%;

    @media screen and (max-width: 575px) {
      padding: 40px 20px;
    }

    .icon {
      height: 105px;
      display: flex;
      align-items: flex-end;
    }

    img {
      display: block;
      margin: 0 auto;
      -webkit-filter: invert(20%) sepia(95%) saturate(1155%) hue-rotate(196deg) brightness(95%) contrast(100%);
      filter: invert(20%) sepia(95%) saturate(1155%) hue-rotate(196deg) brightness(95%) contrast(100%);
      width: 90px;
    }

    h4 {
      text-align: center;
      font-size: 22px;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      margin: 0;
      padding: 20px 0 0 0;
    }

    p {
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      padding: 20px 0;
      max-width: 550px;
      margin: 0 auto;
    }

    a {
      text-align: center;
      display: block;
      text-decoration: none;
      text-transform: uppercase;
      font-family: "Roboto", sans-serif;
      color: #144795;
      font-weight: 700;
      letter-spacing: 4px;
      font-size: 14px;
    }

    &.seller_r {
      img {
        filter: invert(100%) sepia(0%) saturate(22%) hue-rotate(323deg) brightness(105%) contrast(106%);
      }

      h4 {
        color: #fff;
      }

      p {
        color: #fff;
      }

      a {
        color: #fff;
      }
    }


  }

}


// Sayan css 10/03/2023

.bannerAdvSection {
  position: relative;
  padding-bottom: 70px;

  .item {
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .feedVideoWrap {
    order: -1;
  }
}


.bannerAdvSection h3 {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  padding-bottom: 30px;
}

.bannerAdvSection span {
  font-weight: 500;
}

.bannerAdvSection .row {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.bannerAdvSection .container {
  max-width: 93%;
}

.bannerAdvSection .item-img img {
  width: 100%;
}

.bannerAdvSection .owl-prev {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  background: #fff;
  color: #000;
  border-radius: 100%;
  font-size: 20px;
}

.bannerAdvSection .owl-prev:hover {
  background: #144795;
  color: #fff;
  -webkit-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
}

.bannerAdvSection .owl-next {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  width: 30px;
  height: 30px;
  background: #fff;
  color: #000;
  border-radius: 100%;
  font-size: 20px;
}

.bannerAdvSection .owl-next:hover {
  background: #144795;
  color: #fff;
  -webkit-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
}

.bannerAdvSection .item-img {
  position: relative;
  background: url(../assets/images/feeds-l.jpg) center center/cover;
  height: 100%;
  padding: 200px 0 0 0;

  @media screen and (max-width: 991px) {
    padding: 0;
    background: none;
  }
}

.bannerAdvSection .item-img .item-img-txt {
  background: #fff;
  max-width: 556px;
  margin: 0 auto 20px;
  position: relative;
  padding: 30px 0;
  z-index: 1;

  @media screen and (max-width: 991px) {
    margin: 0;
  }
}

.bannerAdvSection .item-img .item-img-txt h4 {
  // text-align: center;
  font-size: 22px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  margin: 0 auto;
  max-width: 489px;
  padding: 10px 0;
}

.bannerAdvSection .item-img .item-img-txt h5 {
  // text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #888888;
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
  max-width: 489px;
  padding: 10px 0;
}

.bannerAdvSection .item-img .item-img-txt p {
  // text-align: center;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0 auto;
  max-width: 489px;
  padding: 10px 0;
}

.bannerAdvSection .item-img .item-img-txt span {
  display: block;
  text-align: center;
}

.bannerAdvSection .item-img .item-img-txt span a {
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #000;
  text-decoration: none;
}

.bannerAdvSection .item-img::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
}

.bannerAdvSection .item-video {
  background: url(../assets/images/feeds-r.jpg) center center/cover;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;

  @media screen and (max-width: 991px) {
    min-height: 200px;
  }

}

.bannerAdvSection .item-video span {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.bannerAdvSection .item-video span:hover {
  -webkit-transition: all ease-in-out .4s;
  transition: all ease-in-out .4s;
  background: #144795;
}

.bannerAdvSection .item-video::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
}


@media (min-width: 390px) and (max-width: 520px) {
  .Font_style {
    font-size: 20px;
    margin: 0;
  }
}

@media (min-width: 577px) and (max-width: 2400px) {
  .Font_style {
    font-weight: bold;
    font-size: 50px;
    margin: 0;
  }
}

.fw-300{
  font-weight: 300;
}

.fw-700{
  font-weight: 700;
}